@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;700&display=swap");

body {
  font-family: "Poppins", serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* Mobile devices (up to 767px) */
@media (max-width: 767px) {
  .App-logo {
    width: 85%;
  }
}

/* Tablet devices (768px to 1023px) */
@media (min-width: 768px) and (max-width: 1439px) {
  .App-logo {
    width: 80%;
  }
}

/* Big screens (1440px and up) */
@media (min-width: 1440px) {
  .App-logo {
    width: 50%;
  }
}

.App-header {
  background-color: #fbfbfb;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
